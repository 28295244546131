import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import HeaderLogo from '../images/header-logo.png'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
const { Header, Content, Footer } = Layout

export default function PublicTermsOfService() {
  return (
    <>
      <Layout>
        <Helmet title="Terms of Service | SB Express" defer={false} />
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            height: '100%',
          }}
        >
          <img src={HeaderLogo} alt="" className="login-header-image" style={{ height: '80px', padding: '10px 0' }} />
        </Header>
        <Content
          className="site-layout"
          style={{
            padding: '0 50px',
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 380,
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
                paddingBottom: 30,
              }}
            >
              <Breadcrumb.Item onClick={() => navigate('/')}>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Terms of Service</Breadcrumb.Item>
            </Breadcrumb>
            <div class="w-richtext">
              <p>
                S.B Express Inc (“S.B Express Inc” or the “Company”) offers shipping service (the “Service”) according to the terms of service (the
                “Terms of Service”), as well as the privacy policy (the “Privacy Policy”), which together constitute an agreement (the “Agreement”) as
                declared below. The Company reserves the right to modify these terms without notice. Your continued usage of the Service constitutes
                your acceptance of these terms. Violation of any terms will result in termination of your account.
              </p>
              <h4>General Terms</h4>
              <ol>
                <li>
                  The Service is not intended for children under 13. By using the Service, you are representing that you are at least 18, or that you
                  are at least 13 years old and have your parents’ permission to use the Service.
                </li>
                <li>
                  The Company may prohibit any user from using the Service in its sole discretion at any time for any reason and is not liable for any
                  damage or loss resulting from such prohibition.
                </li>
                <li>
                  You are responsible for using the Service in a private and secure manner. The Company is not liable for any damage or loss due to
                  unauthorized account access resulting from your actions.
                </li>
                <li> You may not use the Service for any illegal activity or to violate any laws in your jurisdiction. </li>
                <li> You may not use the Service to distribute unsolicited emails (“spam”) or malicious content such as viruses or worms. </li>
                <li> You may not exploit the Service to access unauthorized information. </li>
                <li>
                  Any abuse or threatened abuse of other users of the Service or of Company personnel will result in immediate account termination.
                </li>
                <li> Any failure of the Company to enforce or exercise a right provided in these terms is not a waiver of that right. </li>
                <li> Should any provision of these terms be found invalid or unenforceable, the remaining terms shall still apply. </li>
                <li>
                  This Terms of Service constitutes the entire agreement between you and the Company and supersedes any and all previous agreements,
                  written or oral, between you and the Company, including previous versions of the Terms of Service.
                </li>
                <li>
                  These Terms of Service shall be governed by and construed in accordance with the laws of the State of Illinois, without giving
                  effect to principles of conflicts of law. You and the Company each agree that any action at law or in equity arising out of or
                  relating to these Terms of Service will be filed only in the state or federal courts in the State of Illinois, County of Cook, and
                  hereby consent and submit to the personal and exclusive jurisdiction of such courts for the purposes of litigating any such action.
                </li>
              </ol>
              <h4>
                <strong>Modification to Service</strong>
              </h4>
              <ol>
                <li>The Company reserves the right to modify, suspend, or discontinue the Service for any reason, with or without notice. </li>
              </ol>
              <h4>
                <strong>User Conduct and Submissions</strong>
              </h4>
              <ol>
                <li>
                  Subject to the license granted below, any remarks, ideas, graphics, photographs, or other information (collectively, “Content”)
                  communicated to the Account Holder (as defined below) and posted on the Service in a Hosted Area (as defined below) belong to the
                  person who posted such content. You may use any Content posted by you in any other way without restriction. You may only use Content
                  posted by others in the ways described in these Terms of Service.
                </li>
                <li>
                  You give the party (the “Account Holder”) who owns the domain name as hosted on the Service in which you post your Content (the
                  “Hosted Area”) a non-exclusive, royalty-free, worldwide, perpetual, irrevocable, fully-sublicensable license to publish and use your
                  Content. In addition to the right to publish, you also grant the Account Holder under said license the following rights, without
                  limitation: (i) the right to reproduce or copy or create derivative works thereof; (ii) the right to transfer, deliver, and sell the
                  Content, which includes the distribution via computer and networks; (iii) the right to edit, modify, adapt, arrange, improve,
                  correct, develop, translate, in all or in part; (iv) the right to update/upgrade by adding or removing; (v) the right to film,
                  perform or post the Content in any media, and (vi) the right to use or incorporate all or any part of the Content in any products or
                  services of the Account Holder (whether or not associated with the Service). Except as described in our Privacy Policy, neither the
                  Company nor any Account Holder will be required to treat any Content as confidential. By posting Content you hereby waive any and
                  all rights to be compensated by the Company or any Account Holder for such Content.
                </li>
                <li>
                  In order to operate and improve the Service, the Company needs the right to make certain uses of your Content as well. Therefore,
                  when you post Content in any Hosted Area, you also agree to grant the Company an irrevocable, perpetual, worldwide, royalty-free,
                  fully licensable, non-exclusive license to copy, distribute, sell, publicly display, publicly perform and make derivative works of
                  your Content on the Service and on services affiliated with the Service regardless of the form of media used or of whether such
                  services now exist or are developed in the future.
                </li>
                <li>
                  The submission of your Content on the Service is entirely voluntary, non-confidential, gratuitous, and non-committal. You understand
                  that the Account Holder may be working on the same or similar Content, that it may already know of such Content from other sources,
                  that it may simply wish to develop this (or a similar Content) on its own or it may have taken/will take some other action. In
                  return for the Account Holder’s review and consideration of your Content, you acknowledge that you have read, understand and agree
                  to the terms enumerated below, and further agree that these terms shall apply to any additional material previously or later
                  submitted:
                </li>
                <li>
                  The Content represents your own original work. You have all necessary rights to disclose the Content to the Account Holder. In doing
                  so, you are not violating the rights of any third party and you know of no other individual or entity whose rights will be infringed
                  upon by the Account Holder’s review and/or use of the Content.
                </li>
                <li>
                  You understand that disclosure of Content to the Account Holder does not establish a confidential relationship or obligate the
                  Account Holder to treat your Content (or any related materials) as secret or confidential.
                </li>
                <li>
                  You understand that the Account Holder has no obligation, either express or implied, to develop or use your Content and that no
                  compensation is due to you or anyone else for any inadvertent or intentional use of your Content, related ideas or ideas derived
                  from your idea. You understand that the Account Holder assumes no obligation with respect to any of your Content unless and until
                  the Account Holder enters into a written contract with you, and then only as expressed in that contract. In the absence of a
                  separate formal contract, your rights shall be limited to those existing under the patent laws of the United States.
                </li>
                <li>
                  If your Content is the subject of a pending or issued patent, you have disclosed or will disclose that fact to the Account Holder.
                  To the extent you hold a patent in the Content, the Account Holder acknowledges that no license under any patent is herein granted
                  to the Account Holder. Any license to use a patented Content shall be in the form of a written contract, in which event the Account
                  Holder’s obligations shall be only those expressed in such contract.
                </li>
                <li>
                  The Account Holder will give your Content such consideration as is warranted by its sole judgment. The Account Holder’s review of
                  your Content shall not be construed as any recognition of the novelty or originality of the Content. The Account Holder is not
                  obligated to give reasons for rejecting your Content or to reveal the Account Holder’s activities that are related to the subject
                  matter of the submitted Content.
                </li>
                <li>
                  Neither the discussion or negotiations between the Account Holder and you relating to the possible purchase or license of the
                  Content, nor the making of any offer for the purchase or license of the Content, shall prejudice the Account Holder in any way, or
                  be construed as recognition of the novelty, originality, priority, other rights or value of the submitted Content. Further, the
                  Account Holder’s consideration or its discussions or negotiations with you will not in any way impair the Account Holder’s right to
                  contest the validity or infringement of your rights.
                </li>
                <li>
                  You acknowledge that the Account Holder, directly or indirectly, may (i) be working on the same Content or a similar Content to the
                  Content, or (ii) already know of such Content from other sources, or (iii) simply wish to develop this Content or as similar to the
                  Content on its own.
                </li>
                <li>
                  You hereby irrevocably release and forever discharge the Company and all Account Holders and their affiliates and subsidiaries
                  (together, the “Released Parties”) from any and all actions, causes of actions, claims, damages, liabilities and demands, whether
                  absolute or contingent and of any nature whatsoever, which you now have or hereafter can, shall or may have against the Released
                  Parties or their respecting successors and assigns with respect to the Content, including without limitation in respect of how the
                  Company and its affiliates and subsidiaries, directly or indirectly, use the Content, with the sole exception in respect of the
                  foregoing release and discharge being your right to bring a claim of patent infringement.
                </li>
                <li>
                  The Company has the right, but not the obligation, to remove Content that it determines in its sole discretion to be unlawful,
                  offensive, threatening, libelous, defamatory, obscene or otherwise objectionable, that violates any party’s intellectual property,
                  or that is detrimental to the quality or intended spirit of the Service. The Company also has the right, but not the obligation, to
                  limit or revoke the use privileges of account of anyone who posts such Content.
                </li>
                <li>
                  The Company reserves the right to remove any Content from the service, at its sole discretion, including unacceptable content.
                </li>
                <li> Examples of unacceptable Content or behavior on the Service include: :</li>
              </ol>
              <ol>
                <li> abuse, harassment, threats, flaming or intimidation of any person or organization </li>
                <li> engaging in or contributing to any illegal activity or activity that violates others’ rights </li>
              </ol>
              <h4>Use of derogatory, discriminatory or excessively graphic language </h4>
              <ol>
                <li> providing information that is false, misleading or inaccurate </li>
                <li> hacking or modifying the Company Service or another Web site to falsely imply an association with the Company </li>
                <li>
                  implying or pretending to be affiliated with a company or organization with which you are not affiliated, or misrepresenting the
                  extent of your affiliation or role with an affiliated company or organization
                </li>
              </ol>
              <h4>Transmitting worms, viruses or harmful software</h4>
              <h4>Sending spam</h4>
              <ol>
                <li> disclosing personal or proprietary information of another user, person or organization.</li>
                <li>
                  The Company cannot control all Content posted by third parties to the Service, and does not guarantee the accuracy, integrity or
                  quality of such Content. You understand that by using the Service you may be exposed to Content that you may find offensive,
                  indecent, incorrect or objectionable, and you agree that under no circumstances will the Company be liable in any way for any
                  Content, including any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of your use of any
                  Content. You understand that you must evaluate and bear all risks associated with the use of any Content, including any reliance on
                  the content, integrity, and accuracy of such Content. If you would like to report objectionable materials, you may use the “Flag for
                  review” feature on any topic or reply. Urgent issues can be reported to http://mysbexpress.com/.
                </li>
                <li>
                  Use of the Service is also governed by our<a href="https://www.sbexpress.info/privacy-policy" target="_blank" rel="noreferrer"> privacy policy.</a>
                </li>
              </ol>
              <h4>
                <strong>Copyright, Ownership, and Data Security</strong>
              </h4>
              <ol>
                <li>
                  The Company owns intellectual property rights to any protectable part of the Service, including but not limited to the design,
                  artwork, functionality, and documentation. You may not copy, modify, or reverse engineer any part of the Service owned by the
                  Company.
                </li>
                <li>
                  The Company is not liable for any damages or losses resulting from the Service transmitting information such as invoices and
                  personal messages over unencrypted networks such as email.
                </li>
                <li>
                  The Company respects the intellectual property of others. It may, in appropriate circumstances and at its discretion, disable and/or
                  terminate the accounts of users who repeatedly infringe others’ rights. If you believe that your work has been copied in a way that
                  constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide the following
                  information to the Company’s copyright agent:
                </li>
              </ol>
              <ul>
                <li>
                  an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual
                  property interest;
                </li>
                <li>a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
              </ul>
              <ul>
                <li>a description of where the material that you claim is infringing is located on the site;</li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or
                  the law;
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the
                  copyright or intellectual property owner or are authorized to act on the copyright or intellectual property owner’s behalf.
                </li>
                <li>The Company’s agent for notice of claims of copyright or other intellectual property infringement can be reached as follows:</li>
              </ul>
              <p>By Mail:</p>
              <p>S.B Express Inc</p>
              <p>1451 Elmhurst Rd</p>
              <p>Elk Grove Village IL 60007</p>
              <p>
                <strong>By email:</strong> <a href="mailto:info@sbexpress.com">info@sbexpress.com</a>
              </p>
              <ul>
                <li>Other Terms</li>
              </ul>
              <ol>
                <li>
                  <strong>Representations and Warranties.</strong>Without limiting any other representation, warranty or covenant herein, each party
                  hereby represents and warrants to the other party that: (a) it has the full right, power and authority to enter into this Agreement;
                  (b) this Agreement is a valid and binding obligation of such party; and (c) it has obtained and shall maintain throughout the term
                  of this Agreement all necessary licenses, authorizations, approvals and consents to enter into and perform its obligations hereunder
                  in compliance with all applicable laws, rules and regulations..
                </li>
                <li>
                  Each party agrees to defend, indemnify and hold the other party and its affiliates harmless from and against any third-party claim
                  and any related losses, costs, liabilities and expenses, including reasonable attorneys’ fees, arising out of the breach of any
                  representations, warranties or covenants made by such party herein, or out of such party’s negligence or willful misconduct. Each
                  party (the “Indemnified Party”) reserves the right, at the other party’s expense, to assume the exclusive defense and control of any
                  matter for which the other party is required to indemnify the Indemnified Party and the other party agrees to cooperate with the
                  Indemnified Party’s defense of such claims.
                </li>
                <li>
                  <strong>Warranties; Disclaimers.</strong>The Company hereby represents and warrants that neither the Service nor use of the iCodice®
                  Platform as contemplated herein will infringe on the rights of any third parties or violate any applicable laws or regulations,
                  including, without limitation copyright, trademark and patent laws. UNLESS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE COMPANY MAKES
                  NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO ANY MATTER, INCLUDING WITHOUT LIMITATION ADVERTISING AND OTHER SERVICES, AND
                  EXPRESSLY DISCLAIMS THE IMPLIED WARRANTIES OR CONDITIONS OF NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR PURPOSE.
                  THE COMPANY ALSO DOES NOT WARRANT THE RESULTS OF USE OF THE SERVICE, AND ACCOUNT HOLDER ASSUMES ALL RISK AND RESPONSIBILITY WITH
                  RESPECT THERETO.
                </li>
                <li>
                  <strong>Limitation of Liability and Damages.</strong>UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SYSTEM
                  FAILURE OR NETWORK OUTAGE, WILL EITHER PARTY OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM THIS AGREEMENT, EVEN IF SUCH PARTY OR ITS AUTHORIZED REPRESENTATIVE HAS
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. EXCEPT FOR THE INDEMNITY OBLIGATIONS SET FORTH HEREIN, IN NO EVENT WILL EITHER
                  PARTY’S OR ITS AFFILIATES’ TOTAL LIABILITY TO THE OTHER PARTY FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR
                  RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT OR TORT, INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE) EXCEED THE AMOUNTS PAID BY
                  ACCOUNT HOLDER TO THE COMPANY HEREUNDER.
                  <br />
                </li>
              </ol>
              <p>‍</p>
            </div>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          ©2023 S.B.Express inc
        </Footer>
      </Layout>
    </>
  )
}
